import Head from "next/head";

import type { NextPage } from "next";

import Blocked from "../components/common/Blocked";
import MainLayout from "../components/layouts/MainLayout";

const Home: NextPage = () => {
  return (
    <MainLayout>
      <Head>
        <title>GCert - Bem Vindo</title>
      </Head>

      <Blocked />
    </MainLayout>
  );
};

// noinspection JSUnusedGlobalSymbols
export default Home;
